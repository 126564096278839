import React from "react"
import * as styles from "./headerMenu.module.scss";
import { Link } from "gatsby"
import Arrow from "../../assets/arrow"
import Sprung from "../../assets/sprung"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SocialMedia from "./social";
//import { motion, AnimatePresence} from "framer-motion";
import { motion, AnimatePresence } from "framer-motion";




const HeaderMenu = ({ menuItems, socialMediaLinks, menuGlobal, isOpen, toggleNavbar }) => {

   
  const transition = {duration:.8, ease: [0.6, 0.05, 0.67, 0.97]}  
  const transitionMenu = {duration:.3, ease: [0.47, 0.05, 0.67, 0.97]}  

  const variantsList= {
    animate:{
        transition:{
            staggerChildren:.1, 
            delayChildren: .2,
        }
    }

  }


  const variantsLink = {
    initial:{y:10, opacity: 0}, 
    animate:{y:0, opacity:1}
  }
  
  return (
    <AnimatePresence initial={false}>
    { isOpen && (
        <motion.div exit={{opacity:0}} transition={transitionMenu} className={isOpen ? `${styles.menuOpen} menu-bg` : styles.menu}>
            <div className={isOpen ? styles.menuLinksWrapper : null}>
                <div className={styles.menuContentWrapper}>
                    <GatsbyImage
                        alt={menuGlobal?.defaultImage?.altText}
                        image={getImage(menuGlobal?.defaultImage?.localFile?.childImageSharp?.gatsbyImageData)}
                        className={styles.menuDefaultImage}
                    />
                    <div className={styles.sprungIcon}>
                        <Sprung/>
                    </div>
                    <div className={styles.textOutlined}>
                        <span className='textOutlined'>Linki</span>
                    </div>
                    <motion.ul 
                    variants={variantsList}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    transition={transition}
                    className={styles.menuList}>
                        {menuItems.map((menuItem, index)  => {
                            const featuredImage = {
                                data: menuItem?.details?.image?.localFile?.childImageSharp?.gatsbyImageData,
                                alt: menuItem?.details?.image?.altText || ``,
                            }
                            return (
                                <motion.li variants={variantsLink} className={styles.menuSingleLink} key={index}>
                                    <span className="bold-text-small">{ menuItem?.details?.menuIndex < 9 &&  <span>0</span> } 
                                        {menuItem?.details?.menuIndex} 
                                    </span>
                                    <Link to={menuItem?.details?.link} onClick={toggleNavbar}
                                        className={`${styles.menuSingleLinkItem} menu-link`} aria-label={menuItem?.details?.name}>
                                        <div className={styles.arrowIcon}  >
                                            <Arrow/>
                                        </div>
                                        {menuItem?.details?.name}
                                    </Link>
                                    <GatsbyImage
                                        alt={featuredImage.alt}
                                        image={getImage(featuredImage.data)}
                                        className={styles.menuLinkImage}
                                    />
                                </motion.li>
                            )
                        })}
                    </motion.ul>
                </div>
                
                <GatsbyImage
                    alt={menuGlobal?.backgroundImage?.altText}
                    image={getImage(menuGlobal?.backgroundImage?.localFile?.childImageSharp?.gatsbyImageData)}
                    className={styles.menuBackgroundImage}
                />
                <div className={styles.socialMediaHeaderLinks}>
                    <SocialMedia socialMediaLinks={socialMediaLinks}/>
                </div>
        </div>
        </motion.div>
    )
    }
    </AnimatePresence>
  )
}

export default HeaderMenu;