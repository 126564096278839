import * as React from "react"

const Arrow = () => {
  return (
    <svg width="62" height="63" viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.3686 3.18769C45.9177 3.18769 58.5228 15.7928 58.5228 31.3419C58.5228 46.891 45.9177 59.4961 30.3686 59.4961C14.8194 59.4961 2.21435 46.891 2.21435 31.3419C2.21435 15.7928 14.8194 3.18769 30.3686 3.18769Z" fill="#BED62F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.5473 31.5C60.5473 14.9201 47.1067 1.47939 30.5267 1.47939C13.9468 1.47939 0.506141 14.9201 0.506142 31.5C0.506142 48.0799 13.9468 61.5206 30.5267 61.5206C47.1067 61.5206 60.5473 48.0799 60.5473 31.5ZM61.0535 31.5C61.0535 14.6405 47.3862 0.973245 30.5267 0.973246C13.6673 0.973247 -2.07132e-06 14.6405 -1.33437e-06 31.5C-5.97416e-07 48.3594 13.6673 62.0267 30.5267 62.0267C47.3862 62.0267 61.0535 48.3594 61.0535 31.5Z" fill="#BED62F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.1113 22.0668L21.7672 22.0668L21.7672 20.8674L40.5597 20.8674C40.8909 20.8674 41.1594 21.1359 41.1594 21.4671L41.1594 40.2596L39.96 40.2596L39.96 22.9142L20.7774 42.0969L19.9293 41.2488L39.1113 22.0668Z" fill="#232323"/>
    </svg>
  )
}

export default Arrow