import React from "react"
import * as styles from "./newsletter.module.scss";
import ArrowSingle from "../../assets/arrowSingle";
import { navigate } from "gatsby";
import {useState} from 'react';




const Newsletter = ({ newsletterData }) => {


  

  const [error, setError] = useState(null);
  const [state, setState] = useState({})
  

    function encode(data) {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
      }


    const handleChange = (e) => {
      const formValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
      if(!formValidator.test(e.target.value)){
        setError('Prosimy o wpisanie poprawnego adresu email');
        } else {
          setError(null);
        }
      setState({ ...state, [e.target.name]: e.target.value })
    }


    const handleSubmit = (e) => {
      e.preventDefault()
        const form = e.target
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...state,
          }),
        })
        .then(() => navigate(form.getAttribute('action')))
          .catch((error) => alert(error))
    }
    


  return (

        <div className={`${styles.newsletterContainer} container bg-primary`}>
            <div className={styles.newsletterHeader}>
                <span className="text-uppercase subtitle newsletter-text">{newsletterData?.subheader}</span>
                <h2 className="section-heading">{newsletterData?.header}</h2>
            </div>
            <div className={styles.formContainer}>
                <form className={styles.form} name="Newsletter Form"
                    method="POST"
                    data-netlify="true"
                    action="/subskrypcja/"
                    netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}>
                    <input type="hidden" name="form-name" value="Newsletter Form" />
                    <p hidden><label>Nie wypełniaj tego pola<input name="bot-field"/></label></p>
                    <input required type="email" onChange={handleChange} name="email" placeholder="Twój adres email" aria-label="email" className="form-input"/>
                    <button type="submit" className={styles.formBtn} aria-label={newsletterData?.header}>
                        <ArrowSingle/>
                    </button>
                </form>
                <div className={styles.consentContainer}>
                {error && <p style={{color: '#C70000'}}>{error}</p>}
                    <p className="newsletter-text">{newsletterData?.consent?.text}</p>
                    <a href={newsletterData?.consent?.file?.mediaItemUrl} aria-label={newsletterData?.consent?.name}
                        className="footer-link newsletter-text" rel="noopener noreferrer" target="_blank">
                        {newsletterData?.consent?.name}
                    </a>
                </div>
            </div>
        </div>
  )
}

export default Newsletter;