import React from "react"
import * as styles from "./footer.module.scss";
import { Link } from "gatsby"
import SocialMedia from "./social";
import Logo from "../../assets/logo";
import Newsletter from "./newsletter";
import { globalHistory } from "@reach/router"



const Footer = ({documents, newsletterData, footerLinks, contactDetails, socialMediaLinks}) => {

  const path = globalHistory.location.pathname
  const isSubscriptionPage = path === '/subskrypcja/'
  
  return (
        <footer className={!isSubscriptionPage ? `${styles.footerContainer} footer` : `${styles.footerContainerFull} footer`}>
            {!isSubscriptionPage &&
              <Newsletter newsletterData={newsletterData}/>
            }
            
            <div className={`${styles.footerTop} container`}>
                <div className={styles.footerTopContainer}>
                  <p className="footer-subheading text-uppercase footer-header">Ważne Linki</p>
                  <div className={styles.footerInfoWrapper}>
                    {footerLinks.map((footerLink, index)  => {
                        return (
                          <Link to={footerLink?.details?.link} key={index}
                          className={styles.footerLink} aria-label={footerLink?.details?.name}>
                            {footerLink?.details?.name}
                          </Link>
                        )
                    })}
                    <Link to="/wesprzyj-dzialania" 
                      className={styles.footerLink} aria-label="Deklaracja Zgodności">
                            Wesprzyj Działania
                    </Link>

                  </div>
                </div>
                <div className={styles.footerTopContainer}>
                <p className="footer-subheading text-uppercase footer-header">Dokumenty</p>
                  <div className={styles.footerInfoWrapper}>
                    {documents.map((documentLink, index)  => {
                        return (
                          <a  key={index} href={documentLink?.details?.file?.mediaItemUrl} aria-label={documentLink?.details?.name}
                          className={styles.footerLink} rel="noopener noreferrer" target="_blank">
                            {documentLink?.details?.name}
                          </a>
                        )
                    })}
                    <Link to="/deklaracja-dostępności" 
                      className={styles.footerLink} aria-label="Deklaracja Zgodności">
                            Deklaracja Dostępności
                    </Link>
                  </div>
                </div>
                <div className={styles.footerTopContainer}>
                <p className="footer-subheading text-uppercase footer-header">Kontakt</p>
                  <div className={styles.footerInfoWrapper}>
                    <div className={styles.detailWrapper}>
                      <p>Zapisy Sekcja Trampolina:</p>
                      <p className="bold">{contactDetails?.phone}</p>
                    </div>
                    <div className={styles.detailWrapper}>
                      <p>Zapisy Sekcja Narciarska:</p>
                      <p className="bold">{contactDetails?.contactskiing}</p>
                    </div>
                    <div className={styles.detailWrapper}>
                      <p>Biuro:</p>
                      <p className="bold">
                        <Link to='#' onClick={(e) => {
                          window.location.href = 'mailto:biuro@uksalfa.pl?subject=Zapytanie';
                          e.preventDefault();
                          }}>
                          {contactDetails?.email}
                        </Link>
                      </p>
                    </div>
                    <div className={styles.detailWrapper}>
                      <p>NIP:</p>
                      <p className="bold">{contactDetails?.nip}</p>
                    </div>
                    <div className={styles.detailWrapper}>
                      <p>REGON:</p>
                      <p className="bold">{contactDetails?.regon}</p>
                    </div>
                    <div className={styles.detailWrapper}>
                      <p>Nr Konta Składki:</p>
                      <p className="bold">{contactDetails?.bankAccountPayments}</p>
                    </div>
                    <div className={styles.detailWrapper}>
                      <p>Nr Konta Darowizny:</p>
                      <p className="bold">{contactDetails?.bankAccountSupport}</p>
                    </div>
                    <div className={styles.detailWrapper}>
                      <p>Siedziba Klubu:</p>
                      <div className={styles.addressWrapper}>
                        <p className="bold">{contactDetails?.addressDetails?.address01}</p>
                        <p className="bold">{contactDetails?.addressDetails?.address02}</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className={`${styles.footerBottom} footer-border container`}>
                <div className={styles.footerBottomWrapper}>
                  <div className={styles.socialMediaContainer}>
                    <SocialMedia socialMediaLinks={socialMediaLinks}/>
                  </div>
                  <div className={`${styles.logoWrapper} logo`}>
                    <Logo/>
                  </div>
                </div>
                
                <div className={styles.copyrightsContainer}>
                  {/*<p>Design, Development & Maintenance &hearts; <a href="https://www.katgolek.eu/" rel="noopener noreferrer" target="_blank" className="bold text-primary" aria-label="Designed & Developed by paisak4u">paisak4u</a></p>*/}
                  <p>Design, Development &hearts; <span className="bold text-primary">paisak4u</span></p>
                  <p>&copy;{new Date().getFullYear()} UKS ALFA. Wszelkie Prawa Zastrzeżone.</p>
                </div>
            </div>
            
        </footer>
  )
}

export default Footer;