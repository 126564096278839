import React from "react"
import Footer from "./global/footer"
import Header from "./global/header"
import Preloader from "./global/preloader"
import { useStaticQuery, graphql } from "gatsby"
import { createContext, useState, useEffect } from "react";
import { motion, useScroll, useSpring, AnimatePresence} from "framer-motion";


import "../styles/layout.scss"


export const ThemeContext = createContext(null);


export default function Layout({ children, location }) {



 const data = useStaticQuery(graphql`
 {
  allWpCustomMenu(sort: {details: {menuIndex: ASC}}){
    nodes {
      details {
        image {
          altText
          id
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
       }
        link
        name
        menuIndex
      }
    }
  }
  allWpGlobal {
    nodes {
      globalSettings {
        menu {
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: WEBP)
              }
            }
          }
          defaultImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: WEBP)
              }
            }
          }
        }
        socialMedia {
          link01 {
            link
            name
          }
          link02 {
            link
            name
          }
          link03 {
            link
            name
          }
          link04 {
            link
            name
          }
        }
        contactDetails {
          addressDetails{
            address01
            address02
          }
          email
          contactskiing
          phone
          regon
          nip
          bankAccountPayments
          bankAccountSupport
        }
        cta
        newsletterData {
          header
          subheader
          consent {
            file {
              mediaItemUrl
            }
            name
            text
          }
        }
      }
    }
  }
  allWpDocument(filter: {details: {isFeaturedInFooter: {eq: true}}}) {
    nodes {
      details {
        isFeaturedInFooter
        name
        file {
          mediaItemUrl
        }
      }
    }
  }
  allWpFooterLinks:allWpCustomMenu(filter: {details: {isFeaturedInFooter: {eq: true}}}) {
    nodes {
      details {
        name
        link
        isFeaturedInFooter
      }
    }
  }
}
`)



const [theme, setTheme] = useState("light");

useEffect(() => setTheme('light'), [])

const toggleTheme = () => {
  setTheme((curr) => (curr === "light" ? "dark" : "light"));
};

const { scrollYProgress } = useScroll();
const scaleX = useSpring(scrollYProgress, {
  stiffness: 100,
  damping: 30,
  restDelta: 0.001
});

const duration = 0.57
const variants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: duration,
      delay: .77,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: 140,
    transition: { duration: duration },
  },
}

const menuItems = data.allWpCustomMenu.nodes
const globalData = data.allWpGlobal.nodes[0]
const documentLinks = data.allWpDocument.nodes
const newsletterData = data.allWpGlobal.nodes[0].globalSettings.newsletterData
const contactDetails = data.allWpGlobal.nodes[0].globalSettings.contactDetails
const footerLinks = data.allWpFooterLinks.nodes



  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div id={theme}>
      <motion.div className="progress-bar" style={{ scaleX }} />
      <Preloader/>
      <Header toggleTheme={toggleTheme} theme={theme} menuItems={menuItems} menuGlobal={globalData.globalSettings.menu}  
      socialMediaLinks={globalData.globalSettings.socialMedia} cta={globalData.globalSettings.cta}
      />
      <AnimatePresence>
          <motion.main
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {children}
          </motion.main>
      </AnimatePresence>
      <Footer documents={documentLinks} newsletterData={newsletterData} footerLinks={footerLinks}
      contactDetails={contactDetails} socialMediaLinks={globalData.globalSettings.socialMedia}/>
      </div>
    </ThemeContext.Provider>
  )
}

