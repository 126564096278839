import React from "react"
import * as styles from "./social.module.scss";
import { BiLogoTiktok, BiLogoYoutube, BiLogoFacebook, BiLogoInstagram } from "react-icons/bi";


const SocialMedia = ({socialMediaLinks}) => {

  
  return (
        <div className={styles.socialMediaContainer}>
            <a href={socialMediaLinks?.link01?.link} aria-label={socialMediaLinks?.link01?.name}
                className={`${styles.socialMediaLink} social`}
                rel="noopener noreferrer" target="_blank">
                <BiLogoFacebook/>
            </a>
            <a href={socialMediaLinks?.link02?.link} aria-label={socialMediaLinks?.link02?.name}
                className={`${styles.socialMediaLink} social`}
                rel="noopener noreferrer" target="_blank">
                <BiLogoInstagram/>
            </a>
            <a href={socialMediaLinks?.link03?.link} aria-label={socialMediaLinks?.link03?.name}
                className={`${styles.socialMediaLink} social`}
                rel="noopener noreferrer" target="_blank">
                <BiLogoTiktok/>
            </a>
            <a href={socialMediaLinks?.link04?.link} aria-label={socialMediaLinks?.link04?.name}
                className={`${styles.socialMediaLink} social`}
                rel="noopener noreferrer" target="_blank">
                <BiLogoYoutube/>
            </a>
        </div>
  )
}

export default SocialMedia;