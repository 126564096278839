exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualności.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-artykuly-js": () => import("./../../../src/pages/artykuły.js" /* webpackChunkName: "component---src-pages-artykuly-js" */),
  "component---src-pages-deklaracja-dostepnosci-js": () => import("./../../../src/pages/deklaracja-dostępności.js" /* webpackChunkName: "component---src-pages-deklaracja-dostepnosci-js" */),
  "component---src-pages-dokumenty-js": () => import("./../../../src/pages/dokumenty.js" /* webpackChunkName: "component---src-pages-dokumenty-js" */),
  "component---src-pages-dziekujemy-js": () => import("./../../../src/pages/dziekujemy.js" /* webpackChunkName: "component---src-pages-dziekujemy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subskrypcja-js": () => import("./../../../src/pages/subskrypcja.js" /* webpackChunkName: "component---src-pages-subskrypcja-js" */),
  "component---src-pages-sukcesy-js": () => import("./../../../src/pages/sukcesy.js" /* webpackChunkName: "component---src-pages-sukcesy-js" */),
  "component---src-pages-trenerzy-js": () => import("./../../../src/pages/trenerzy.js" /* webpackChunkName: "component---src-pages-trenerzy-js" */),
  "component---src-pages-treningi-js": () => import("./../../../src/pages/treningi.js" /* webpackChunkName: "component---src-pages-treningi-js" */),
  "component---src-pages-wesprzyj-dzialania-js": () => import("./../../../src/pages/wesprzyj-dzialania.js" /* webpackChunkName: "component---src-pages-wesprzyj-dzialania-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-trainer-js": () => import("./../../../src/templates/trainer.js" /* webpackChunkName: "component---src-templates-trainer-js" */)
}

