/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it



const React = require("react")
const Layout = require("./src/components/layout").default





const transitionDelay = 500;


exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};

exports.onPreRouteUpdate = () => {
  setTimeout(() => {
      document.getElementById("loader-wrapper").style.display = "none"
  }, 700)
}




exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

