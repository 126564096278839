import * as React from "react"

const Sprung = () => {
  return (
    <svg width="202" height="189" viewBox="0 0 202 189" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M188.819 4.54153C184.433 24.1429 169.469 55.4471 190.055 97.7764C210.162 139.122 196.484 19.7436 159.444 3.99721C116.147 -14.4093 152.425 188.666 164.861 151.914C171.819 131.353 131.358 5.65602 108.902 19.793C80.3363 37.7769 111.658 185.409 126.438 186.513C152.511 188.46 96.7173 14.0783 68.1772 21.3973C34.145 30.1247 60.8744 146.98 82.9413 158.012C100.595 166.838 53.8047 48.7694 1.99977 61.356" stroke="#BED62F" strokeWidth="4" strokeLinecap="round"/>
    </svg>
  )
}

export default Sprung