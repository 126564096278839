import * as React from "react"

const AccordionIcon = () => {
  return (
    <svg width="75" height="62" viewBox="0 0 75 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M32.7276 58.3416C18.4111 55.9742 8.70899 42.5924 11.0574 28.4525C11.9528 23.0614 14.4706 18.3384 18.037 14.689C23.514 10.1176 30.9097 7.86723 38.5416 9.12922C52.3673 11.4154 61.7327 24.3625 59.4599 38.0473C57.6763 48.7866 49.2554 56.7888 39.0313 58.6312C36.9707 58.7824 34.8581 58.6939 32.7276 58.3416ZM39.0313 58.6312C50.8178 57.7668 60.9032 49.0612 62.9022 37.0254C65.2505 22.8855 55.5485 9.50374 41.2319 7.13638C32.3739 5.67163 23.8247 8.76669 18.037 14.689C13.5921 18.399 10.4109 23.6377 9.39275 29.7683C7.11993 43.4532 16.4854 56.4003 30.311 58.6865C33.2869 59.1785 36.2269 59.1366 39.0313 58.6312Z" fill="url(#paint0_linear_1013_9419)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.2103 5.30113C55.7327 7.52464 65.717 20.9547 63.5108 35.298C62.6697 40.7665 60.178 45.5782 56.6118 49.3158C51.1224 54.0103 43.6622 56.3781 35.9204 55.1927C21.8959 53.0455 12.2577 40.0514 14.3929 26.1697C16.0685 15.2762 24.4965 7.07319 34.8247 5.08414C36.9091 4.90617 39.049 4.97022 41.2103 5.30113ZM34.8247 5.08414C22.903 6.10204 12.7983 15.0372 10.9205 27.246C8.71426 41.5893 18.6985 55.0193 33.2209 57.2428C42.2066 58.6186 50.8243 55.3817 56.6118 49.3158C61.0666 45.5062 64.2235 40.1643 65.18 33.9457C67.3152 20.064 57.6771 7.06997 43.6526 4.9227C40.6337 4.46048 37.6577 4.53854 34.8247 5.08414Z" fill="url(#paint1_linear_1013_9419)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.77204 36.2484C6.19736 20.5354 16.5648 5.7338 31.9284 3.18815C37.7866 2.21748 43.5254 3.16892 48.5267 5.58891C55.1439 9.66884 60.0455 16.5608 61.4179 24.9367C63.9043 40.1109 53.8662 54.4093 38.9971 56.873C27.3275 58.8066 16.1504 52.8761 10.6787 42.899C9.8028 40.8115 9.15503 38.5857 8.77204 36.2484ZM10.6787 42.899C15.6911 54.8445 28.1739 62.257 41.2521 60.09C56.6157 57.5444 66.9831 42.7428 64.4084 27.0298C62.8155 17.3083 56.6418 9.51548 48.5267 5.58891C43.1558 2.27738 36.6547 0.818423 29.993 1.92222C15.1239 4.38594 5.0857 18.6844 7.57211 33.8586C8.10713 37.1238 9.17847 40.1635 10.6787 42.899Z" fill="url(#paint2_linear_1013_9419)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M66.2893 26.3614C69.084 42.2377 58.7658 57.3496 43.2428 60.1147C37.3252 61.1688 31.5055 60.2764 26.4141 57.8888C19.6648 53.8395 14.6189 46.9212 13.1289 38.4571C10.4301 23.1252 20.4211 8.52677 35.4444 5.85066C47.2332 3.75073 58.6176 9.6159 64.2774 19.6468C65.1901 21.7508 65.8733 23.998 66.2893 26.3614ZM64.2774 19.6468C59.0586 7.61743 46.3345 0.268045 33.1224 2.62151C17.5994 5.38662 7.28116 20.4984 10.0758 36.3747C11.8051 46.1987 18.1501 54.0134 26.4141 57.8888C31.8905 61.1744 38.4883 62.5712 45.2178 61.3725C60.2412 58.6964 70.2322 44.098 67.5333 28.766C66.9522 25.465 65.8303 22.3991 64.2774 19.6468Z" fill="url(#paint3_linear_1013_9419)"/>
        <circle cx="37" cy="31.625" r="24" fill="#C5D950"/>
        <rect x="25" y="32.625" width="2" height="24" rx="1" transform="rotate(-90 25 32.625)" fill="#232323"/>
        <rect x="38" y="43.625" width="2" height="24" rx="1" transform="rotate(180 38 43.625)" fill="#232323"/>
        <defs>
        <linearGradient id="paint0_linear_1013_9419" x1="11.9402" y1="45.6304" x2="53.2198" y2="24.5051" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BED62F"/>
        <stop offset="0.364001" stopColor="#BED62F" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1013_9419" x1="62.4089" y1="17.9181" x2="20.8745" y2="39.8046" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BED62F"/>
        <stop offset="0.364001" stopColor="#BED62F" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1013_9419" x1="14.5713" y1="10.1631" x2="51.0985" y2="45.2402" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BED62F"/>
        <stop offset="0.364001" stopColor="#BED62F" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1013_9419" x1="60.7313" y1="52.8409" x2="23.3318" y2="17.7764" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BED62F"/>
        <stop offset="0.364001" stopColor="#BED62F" stopOpacity="0"/>
        </linearGradient>
        </defs>
    </svg>
  )
}

export default AccordionIcon