import React, { useState, useEffect } from "react"
import * as styles from "./header.module.scss";
import { Link } from "gatsby"
import Logo from "../../assets/logo"
import HeaderMenu from "./headerMenu";
import CloseIcon from "../../assets/accordion"
import { FiMenu, FiX } from "react-icons/fi";
import { BiAccessibility } from "react-icons/bi";
import { globalHistory } from "@reach/router"





const Header = ({ menuItems, socialMediaLinks, menuGlobal, cta, toggleTheme, theme }) => {
const [isOpen, setIsOpen] = useState(false)
const [showModeChange,setShowModeChange ] = useState(false)

useEffect(() => setIsOpen(false), [])
useEffect(() => setShowModeChange(false), [])

const toggleNavbar = () => {
    setIsOpen(!isOpen)
}

const toggleShowModeChange = () => {
    setShowModeChange(!showModeChange)
}

const path = globalHistory.location.pathname
const isSupportPage = path === '/wesprzyj-dzialania/'
const isThankYouPage = path === '/dziekujemy/'

  
  return (
        <header className={styles.header}>
            <div className={styles.navbar}>
                <Link  aria-label="Link do strony początkowej: Home" to='/' className={`${styles.logoWrapper} logo`}>
                    <Logo/>
                </Link>
                <div className={styles.buttonsWrapper}>
                    {showModeChange && 
                        <div className={`${styles.themeTogglerWrapper} theme-modal`}>
                             <button className={styles.themeToggler}>
                                <label className="bold theme-label"> {theme === "light" ? "Wysoki Kontrast / Ciemny Tryb" : "Jasny / Zwykły Tryb"}
                                <input hidden type="checkbox"
                                onChange={toggleTheme} checked={theme === "dark"}
                                /></label>
                            </button>
                            <button type="button" className={styles.closeBtn} onClick={toggleShowModeChange} aria-label="Zamknij Modal Trybu Jasnego lub Ciemnego">
                                <CloseIcon/>
                            </button>
                        </div>
                    }

                    { !isSupportPage && !isThankYouPage &&
                        <Link to='/wesprzyj-dzialania' className={`cta-btn wplacam-container ${styles.headerBtn}`} aria-label={cta}>
                                {cta}
                        </Link>
                    }
                    <button type="button" onClick={toggleShowModeChange} aria-label="Zmiana Trybu: Jasny Tryb / Ciemny Tryb i Wysoki Kontrast"
                        className={`${styles.accessibilityIcon} mode-icon`}>
                        <BiAccessibility/>     
                    </button>
                    <button type="button" onClick={toggleNavbar} aria-label="Otwórz/ Zamknij Menu"
                     className={isOpen ? `${styles.hamburgerMenu} hamburger-bg` : `hamburger-bg ${styles.hamburgerMenu}`}>
                    {!isOpen? <FiMenu/> :  <FiX/> }
                    </button>
                </div>
            </div>
            <HeaderMenu menuItems={menuItems} socialMediaLinks={socialMediaLinks} menuGlobal={menuGlobal} 
            isOpen={isOpen} toggleNavbar={toggleNavbar}/>
        </header>
  )
}
export default Header;