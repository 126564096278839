import * as React from "react"

const ArrowSingle = () => {
  return (
    <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M30.3331 14.647L17.0285 1.52374L17.9486 0.616222L32.3646 14.8358C32.6187 15.0864 32.6187 15.4927 32.3646 15.7433L17.9486 29.9629L17.0285 29.0554L30.3348 15.9304L0.904485 15.9304L0.904486 14.647L30.3331 14.647Z" fill="#C5D950"/>
    </svg>
  )
}

export default ArrowSingle