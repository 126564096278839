// extracted by mini-css-extract-plugin
export var addressWrapper = "footer-module--addressWrapper--3b054";
export var articleCardHeader = "footer-module--article-card-header--4a120";
export var articlePageHeading = "footer-module--article-page-heading--cb547";
export var bannerHeading = "footer-module--banner-heading--1718d";
export var bblFadInOut = "footer-module--bblFadInOut--d4044";
export var bgPrimary = "footer-module--bg-primary--ec5c6";
export var black = "footer-module--black--1f57b";
export var bold = "footer-module--bold--5927f";
export var boldTextLarge = "footer-module--bold-text-large--458eb";
export var boldTextMedium = "footer-module--bold-text-medium--a1207";
export var boldTextSmall = "footer-module--bold-text-small--00e8a";
export var boldTextXs = "footer-module--bold-text-xs--4d5e6";
export var calendarIconMode = "footer-module--calendar-icon-mode--ee4bf";
export var campaignPreview = "footer-module--campaign-preview--eb29f";
export var card = "footer-module--card--a16b7";
export var cardHeader = "footer-module--card-header--6afc3";
export var cardIcon = "footer-module--card-icon--b5d5d";
export var container = "footer-module--container--35ee5";
export var copyrightsContainer = "footer-module--copyrightsContainer--7c5a0";
export var ctaBtn = "footer-module--cta-btn--666d0";
export var dark = "footer-module--dark--0e3a4";
export var declarationHeading = "footer-module--declaration-heading--757a6";
export var detailWrapper = "footer-module--detailWrapper--a9f9a";
export var eventDate = "footer-module--event-date--2209a";
export var footer = "footer-module--footer--551d2";
export var footerBorder = "footer-module--footer-border--ec269";
export var footerBottom = "footer-module--footerBottom--62e75";
export var footerBottomWrapper = "footer-module--footerBottomWrapper--77d66";
export var footerContainer = "footer-module--footerContainer--3f50f";
export var footerContainerFull = "footer-module--footerContainerFull--3ed13";
export var footerHeader = "footer-module--footer-header--82160";
export var footerInfoWrapper = "footer-module--footerInfoWrapper--6cec4";
export var footerLink = "footer-module--footerLink--893f9";
export var footerSubheading = "footer-module--footer-subheading--1a6f1";
export var footerTop = "footer-module--footerTop--26a16";
export var footerTopContainer = "footer-module--footerTopContainer--cef61";
export var formInput = "footer-module--form-input--891f7";
export var hamburgerBg = "footer-module--hamburger-bg--7be61";
export var homePageHeading = "footer-module--home-page-heading--f0846";
export var image = "footer-module--image--2809b";
export var leadParagraph = "footer-module--lead-paragraph--ad2f1";
export var leadSubtitle = "footer-module--lead-subtitle--c879c";
export var leadText = "footer-module--lead-text--ba93a";
export var light = "footer-module--light--6dd1f";
export var link = "footer-module--link--45e22";
export var list = "footer-module--list--11162";
export var loader = "footer-module--loader--1fd97";
export var loaderWrapper = "footer-module--loader-wrapper--280d9";
export var logo = "footer-module--logo--2d724";
export var logoWrapper = "footer-module--logoWrapper--389e0";
export var mainCard = "footer-module--main-card--a067e";
export var mainCardContent = "footer-module--main-card-content--1b330";
export var mainCardImageWrapper = "footer-module--main-card-image-wrapper--11888";
export var marquee = "footer-module--marquee--4d59c";
export var menuBg = "footer-module--menu-bg--92a56";
export var menuLink = "footer-module--menu-link--d990a";
export var modal = "footer-module--modal--37f6f";
export var modeIcon = "footer-module--mode-icon--d7485";
export var modeSwitcher = "footer-module--mode-switcher--89793";
export var newsletterText = "footer-module--newsletter-text--2e779";
export var number = "footer-module--number--ebc84";
export var numberLarge = "footer-module--number-large--9522c";
export var pageHeading = "footer-module--page-heading--0095e";
export var panel = "footer-module--panel--3ade2";
export var panelMagnified = "footer-module--panel--magnified--faef6";
export var preview = "footer-module--preview--76790";
export var progressBar = "footer-module--progress-bar--842c2";
export var sectionBg = "footer-module--section-bg--2af81";
export var sectionBgGradient = "footer-module--section-bg-gradient--d5a72";
export var sectionBgGradientReverse = "footer-module--section-bg-gradient-reverse--ee8d3";
export var sectionContent = "footer-module--section-content--348ec";
export var sectionHeading = "footer-module--section-heading--99bf0";
export var social = "footer-module--social--23193";
export var socialMediaContainer = "footer-module--socialMediaContainer--8b862";
export var subscriptionCard = "footer-module--subscription-card--1fffa";
export var subtitle = "footer-module--subtitle--1df02";
export var supportContainer = "footer-module--support-container--fbf72";
export var swiper = "footer-module--swiper--9c622";
export var swiperButtonNext = "footer-module--swiper-button-next--2b36a";
export var swiperButtonPrev = "footer-module--swiper-button-prev--87af6";
export var swiperPagination = "footer-module--swiper-pagination--f81a9";
export var swiperPaginationProgressbar = "footer-module--swiper-pagination-progressbar--8eb44";
export var swiperWrapper = "footer-module--swiper-wrapper--be604";
export var textOutlined = "footer-module--textOutlined--12798";
export var textOutlinedWrapper = "footer-module--text-outlined-wrapper--a2e3a";
export var textPrimary = "footer-module--text-primary--04e2b";
export var textPrimaryBlack = "footer-module--text-primary-black--7fe74";
export var textPrimaryDark = "footer-module--text-primary-dark--f058c";
export var textUppercase = "footer-module--text-uppercase--f2984";
export var themeLabel = "footer-module--theme-label--f4777";
export var themeModal = "footer-module--theme-modal--30cb3";
export var trainerDetail = "footer-module--trainer-detail--cd2ff";
export var trainersLink = "footer-module--trainers-link--58929";
export var trophyIcon = "footer-module--trophy-icon--c0959";
export var wpVideo = "footer-module--wp-video--509af";
export var wpVideoShortcode = "footer-module--wp-video-shortcode--c098b";
export var wplacamContainer = "footer-module--wplacam-container--f2c62";