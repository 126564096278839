// extracted by mini-css-extract-plugin
export var articleCardHeader = "social-module--article-card-header--07807";
export var articlePageHeading = "social-module--article-page-heading--83d87";
export var bannerHeading = "social-module--banner-heading--99b27";
export var bblFadInOut = "social-module--bblFadInOut--4fc68";
export var bgPrimary = "social-module--bg-primary--8eed9";
export var black = "social-module--black--b274c";
export var bold = "social-module--bold--c8d44";
export var boldTextLarge = "social-module--bold-text-large--7b7d0";
export var boldTextMedium = "social-module--bold-text-medium--2fe41";
export var boldTextSmall = "social-module--bold-text-small--c28a6";
export var boldTextXs = "social-module--bold-text-xs--ee3a7";
export var calendarIconMode = "social-module--calendar-icon-mode--52d2b";
export var campaignPreview = "social-module--campaign-preview--64840";
export var card = "social-module--card--4be51";
export var cardHeader = "social-module--card-header--0b6c4";
export var cardIcon = "social-module--card-icon--941a0";
export var container = "social-module--container--02741";
export var ctaBtn = "social-module--cta-btn--7d6d8";
export var dark = "social-module--dark--e3ccf";
export var declarationHeading = "social-module--declaration-heading--a4a33";
export var eventDate = "social-module--event-date--18bfb";
export var footer = "social-module--footer--b4a4d";
export var footerBorder = "social-module--footer-border--5073c";
export var footerHeader = "social-module--footer-header--e6030";
export var footerSubheading = "social-module--footer-subheading--99e77";
export var formInput = "social-module--form-input--b1c76";
export var hamburgerBg = "social-module--hamburger-bg--278a4";
export var homePageHeading = "social-module--home-page-heading--9a570";
export var image = "social-module--image--f8859";
export var leadParagraph = "social-module--lead-paragraph--4481b";
export var leadSubtitle = "social-module--lead-subtitle--c8775";
export var leadText = "social-module--lead-text--70a22";
export var light = "social-module--light--73c9f";
export var link = "social-module--link--14c24";
export var list = "social-module--list--2e611";
export var loader = "social-module--loader--a4d75";
export var loaderWrapper = "social-module--loader-wrapper--eac91";
export var logo = "social-module--logo--9bda2";
export var mainCard = "social-module--main-card--0bb4c";
export var mainCardContent = "social-module--main-card-content--470de";
export var mainCardImageWrapper = "social-module--main-card-image-wrapper--b43ba";
export var marquee = "social-module--marquee--1a543";
export var menuBg = "social-module--menu-bg--a8294";
export var menuLink = "social-module--menu-link--61d14";
export var modal = "social-module--modal--f3dd2";
export var modeIcon = "social-module--mode-icon--2d0db";
export var modeSwitcher = "social-module--mode-switcher--02ac6";
export var newsletterText = "social-module--newsletter-text--ddc29";
export var number = "social-module--number--8bfc6";
export var numberLarge = "social-module--number-large--70871";
export var pageHeading = "social-module--page-heading--d0842";
export var panel = "social-module--panel--719e3";
export var panelMagnified = "social-module--panel--magnified--f08f2";
export var preview = "social-module--preview--631f3";
export var progressBar = "social-module--progress-bar--3963d";
export var sectionBg = "social-module--section-bg--cfd11";
export var sectionBgGradient = "social-module--section-bg-gradient--409a5";
export var sectionBgGradientReverse = "social-module--section-bg-gradient-reverse--1e87e";
export var sectionContent = "social-module--section-content--5e2a8";
export var sectionHeading = "social-module--section-heading--328b3";
export var social = "social-module--social--d8286";
export var socialMediaContainer = "social-module--socialMediaContainer--e7485";
export var socialMediaLink = "social-module--socialMediaLink--c8bba";
export var subscriptionCard = "social-module--subscription-card--14f11";
export var subtitle = "social-module--subtitle--3afd8";
export var supportContainer = "social-module--support-container--2cfb7";
export var swiper = "social-module--swiper--c8c18";
export var swiperButtonNext = "social-module--swiper-button-next--1b797";
export var swiperButtonPrev = "social-module--swiper-button-prev--6c7c1";
export var swiperPagination = "social-module--swiper-pagination--b0520";
export var swiperPaginationProgressbar = "social-module--swiper-pagination-progressbar--f1b23";
export var swiperWrapper = "social-module--swiper-wrapper--e32f1";
export var textOutlined = "social-module--textOutlined--3a2a4";
export var textOutlinedWrapper = "social-module--text-outlined-wrapper--2d526";
export var textPrimary = "social-module--text-primary--a37fe";
export var textPrimaryBlack = "social-module--text-primary-black--f4041";
export var textPrimaryDark = "social-module--text-primary-dark--d0aa8";
export var textUppercase = "social-module--text-uppercase--0b367";
export var themeLabel = "social-module--theme-label--d959b";
export var themeModal = "social-module--theme-modal--a849a";
export var trainerDetail = "social-module--trainer-detail--798d5";
export var trainersLink = "social-module--trainers-link--8fe1e";
export var trophyIcon = "social-module--trophy-icon--b5f18";
export var wpVideo = "social-module--wp-video--f8d03";
export var wpVideoShortcode = "social-module--wp-video-shortcode--f85d0";
export var wplacamContainer = "social-module--wplacam-container--07338";