import React from "react"
import * as styles from "./preloader.module.scss";
import Logo from "../../assets/logo"





const Preloader = () => {

  
  return (
        <div className={styles.preloader} id="loader-wrapper">
            <div className={`${styles.logoWrapper} logo`}>
                <Logo/>
            </div>
            <span className="loader"></span>
        </div>
  )
}
export default Preloader;